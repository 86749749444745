import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Resources',
      to: '/get-started/for-developers/resources'
    }, {
      name: 'Canvas Kit Preview'
    }]} mdxType="Breadcrumbs" />
    <p>{`This is a group of coming-soon components that have had a full design and a11y review, and are
approved for use in product. Their functionality and design are set, but the APIs and/or underlying
architecture could still be subject to change. This bundle serves as a staging ground for components
that are ready to use, but may not be up to the high code standards upheld in the main
`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{` bundle.`}</p>
    <p>{`Generally, Canvas Kit Preview components were originally in
`}<a parentName="p" {...{
        "href": "/get-started/for-developers/resources/canvas-kit-labs"
      }}>{`Canvas Kit Labs`}</a>{` and have been promoted.
Essentially, Canvas Kit Labs is for alpha components and Canvas Kit Preview is for beta components.`}</p>
    <h2 {...{
      "id": "breaking-changes"
    }}>{`Breaking Changes`}</h2>
    <p>{`Due to the nature of this bundle of components, we will be continuously iterating. This could
potentially mean a breaking change (though much less likely than Labs components). The goal of this
bundle is to work unconstrained by our design system's semantic versioning. This means that
`}<strong parentName="p">{`breaking changes can be deployed to Preview modules at any time and it will not trigger a major
version bump across our other modules`}</strong>{`. Regardless of the fact that we use a fixed version number
for all components, all changes/additions in this bundle will never result in a major version bump
across our component library. Should breaking changes arise, we will provide strong communication
and migration strategies.`}</p>
    <p><strong parentName="p">{`By consuming any of these modules, you acknowledge the potential complexity of updating and
ultimately performing a migration when components are promoted to a stable version.`}</strong></p>
    <p><strong parentName="p">{`These components are purely opt in`}</strong>{`, so they are not included in the main module
`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <ul>
      <li parentName="ul">{`Docs & examples`}</li>
      <li parentName="ul">{`Change announcements & migration strategies`}</li>
      <li parentName="ul">{`Longer stretches between any potential breaking changes`}</li>
      <li parentName="ul">{`Migration to main module might still require some effort`}</li>
      <li parentName="ul">{`Direct support is available, but we prioritize Main components first`}</li>
    </ul>
    <h2 {...{
      "id": "module-structure"
    }}>{`Module Structure`}</h2>
    <pre><code parentName="pre" {...{}}>{`/modules
  ...
  /preview-react
    /new-component
      /* Standard React component structure */
`}</code></pre>
    <h2 {...{
      "id": "creating-a-canvas-kit-preview-component"
    }}>{`Creating a Canvas Kit Preview Component`}</h2>
    <ol>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn create-component`}</inlineCode></li>
      <li parentName="ol">{`When asked `}<inlineCode parentName="li">{`What category should this component live in?`}</inlineCode>{`, select `}<inlineCode parentName="li">{`Preview (beta)`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Your new module will be generated in accordance with the file structure above. It will get a
package name of `}<inlineCode parentName="li">{`@workday/canvas-kit-preview-react/<COMPONENT>`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If you had the storybook server running, you may need to restart it.`}</li>
    </ol>
    <h2 {...{
      "id": "promoting-a-component-out-of-canvas-kit-preview"
    }}>{`Promoting a Component out of Canvas Kit Preview`}</h2>
    <ol>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn promote-component`}</inlineCode></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      